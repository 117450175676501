export const scorePay = {
    active: "airRecharge",
    type: "logo",
    tabs: {
        airRecharge: "机场高铁",
        transportation: "交通出行",
        localLife: "本地生活",
        entertainment: "休闲娱乐"
    },
    data: {
        airRecharge: [
            {
                name: "四川航空",
                jingle: "金熊猫里程",
                link: "https://pay.jifen360.com/airport/consumption.html?platform=scal&$_=" + Math.random(),
                logo: require("@/assets/images/logo/sichuan_air.png"),
            },
            {
                name: "中国国际航空",
                jingle: "凤凰知音里程",
                link: "https://pay.jifen360.com/third/scal-app/morestore.html?type=coupon&cid=22&$_=" + Math.random(),
                logo: require("@/assets/images/logo/china_air.png")
            },
            {
                name: "厦门航空",
                jingle: "白鹭积分",
                link: "https://pay.jifen360.com/xiamenair/xm_airport.html?$_=" + Math.random(),
                logo: require("@/assets/images/logo/xiamen_air.png")
            },
            {
                name: "工商银行",
                jingle: "信用卡积分",
                link: "https://pays.jifen360.com/exchange/poster.html?$_=" + Math.random(),
                logo: require("@/assets/images/logo/icbc.png")
            },
            {
                name: "明宇商旅",
                jingle: "明宇会积分",
                link: "https://pay.jifen360.com/third/myh/airport.html?$_=" + Math.random(),
                logo: require("@/assets/images/logo/myh.png")
            }
        ],
        transportation: [
            {
                name: "天府通地铁卡",
                jingle: "川航里程全额抵",
                link: "https://pay.jifen360.com/third/tianfuTong/index.html?$_=" + Math.random(),
                logo: require("@/assets/images/logo/tft.png")
            },
            {
                name: "厦门地铁/BRT",
                jingle: "白鹭积分兑换",
                link: "https://payment.jifen360.com/xiamenair_metro/?$_=" + Math.random(),
                logo: require("@/assets/images/logo/xiamen_metro.png")
            },
            {
                name: "石家庄地铁",
                jingle: "白鹭积分兑换",
                link: "https://payment.jifen360.com/metro/?metro=shx&$_=" + Math.random(),
                // isNew: true,
                logo: require("@/assets/images/logo/shx.png")
            },
            {
                name: "航旅纵横",
                jingle: "航旅纵横",
                link: "https://payment.jifen360.com/third_coupon/into?template=hanglv&$_=" + Math.random(),
                logo: require("@/assets/images/logo/hanglv.png")
            },
            {
                name: "哈啰单车",
                jingle: "川航里程兑换",
                link: "https://app.e-pointchina.com.cn/gateway/entry/scair_master/app/saschk0100010002?utm_source=CHAPP",
                logo: require("@/assets/images/logo/hello.png"),
                // isNew: true,
            },
            {
                name: "南昌地铁",
                jingle: "白鹭积分兑换",
                link: "https://payment.jifen360.com/metro/?metro=llx",
                logo: require("@/assets/images/logo/llx.png"),
                isNew: true,
            },
        ],
        localLife: [
            {
                name: "红旗连锁",
                jingle: "5000+门店",
                link: "https://pay.jifen360.com/scan/login.html?mark=fa4a650f0bdfd569a4a6fb20bd6d2ad5&$_=" + Math.random(),
                logo: require("@/assets/images/logo/hongqi.png")
            },
            {
                name: "罗森便利店",
                jingle: "1000+门店",
                link: "https://shopping-mall.jifen360.com/pages/lawson/index?$_=" + Math.random(),
                logo: require("@/assets/images/logo/lawson.png")
            },
            {
                name: "山姆会员卡",
                jingle: "线上线下都适用",
                link: "https://shopping-mall.jifen360.com/pages/sams/index?$_=" + Math.random(),
                logo: require("@/assets/images/logo/sams.png")
            },
            // {
            //     name: "云闪付礼包",
            //     jingle: "线上线下都适用",
            //     link: "https://pay.jifen360.com/third/union_pay/?$_=" + Math.random(),
            //     // notNet: true,
            //     logo: require("@/assets/images/logo/union_pay.png")
            // },
            {
                name: "美团零花",
                jingle: "川航里程兑换",
                link: "https://m.sichuanair.com/zschresource/admanage/ad/3782/ad/index.html?time=1673242458958&utm_content=%E7%BE%8E%E5%9B%A20627&$_=" + Math.random(),
                // notNet: true,
                logo: require("@/assets/images/logo/meituan.png")
            },
            {
                name: "生活缴费",
                jingle: "川航里程兑换",
                link: "https://sichuan-airlines.jifen360.com/life_pay/?$_=" + Math.random(),
                // isNew: true,
                logo: require("@/assets/images/logo/live_pay.png")
            },
            {
                name: "菜鸟裹裹",
                jingle: "川航里程兑换",
                link: "https://payment.jifen360.com/third_coupon/into?template=cngg&rand=" + Math.random(),
                // isNew: true,
                logo: require("@/assets/images/logo/cngg.png"),
            },
            {
                name: "乐摩吧",
                jingle: "川航里程兑换",
                link: "https://payment.jifen360.com/third_coupon/into?template=lmb&rand=" + Math.random(),
                // isNew: true,
                logo: require("@/assets/images/logo/lmb.png"),
            },
            {
                name: "欢乐谷",
                jingle: "川航里程兑换",
                link: "https://payment.jifen360.com/third_coupon/into?template=oct&rand=" + Math.random(),
                // isNew: true,
                logo: require("@/assets/images/logo/oct.png"),
            },
            {
                name: "书亦烧仙草",
                jingle: "川航里程兑换",
                link: "https://sichuan-airlines.jifen360.com/coupon_life/?coupon=shuyi&rand=" + Math.random(),
                // isNew: true,
                logo: require("@/assets/images/logo/shuyi.png"),
            },
            {
                name: "瑞幸咖啡",
                jingle: "川航里程兑换",
                link: "https://sichuan-airlines.jifen360.com/coupon_life/?coupon=luckin&rand=" + Math.random(),
                // isNew: true,
                logo: require("@/assets/images/logo/luckin.png"),
            },
            {
                name: "见福便利店",
                jingle: "航空积分兑换",
                link: "https://payment.jifen360.com/jf_mark/?mark=908D0E4DFD588E10CA40B68662C4565D&randome=20240312&rand=" + Math.random(),
                isNew: true,
                logo: require("@/assets/images/logo/jf.png"),
            },
            {
                name: "良品铺子",
                jingle: "川航里程兑换",
                link: "https://payment.jifen360.com/third_coupon/?template=lppz&recharge=scal&rand=" + Math.random(),
                isNew: true,
                logo: require("@/assets/images/logo/lppz.png"),
            },
        ],
        entertainment: [
            {
                name: "爱奇艺",
                jingle: "航空积分兑换",
                link: "https://payment.jifen360.com/third_coupon/into?template=iqiyi&rand=" + Math.random(),
                // isNew: true,
                logo: require("@/assets/images/logo/iqiyi.png")
            },
            {
                name: "优酷",
                jingle: "航空积分兑换",
                link: "https://payment.jifen360.com/third_coupon/into?template=youku&rand=" + Math.random(),
                // isNew: true,
                logo: require("@/assets/images/logo/youku.png")
            },
            {
                name: "喜马拉雅",
                jingle: "航空积分兑换",
                link: "https://payment.jifen360.com/third_coupon/into?template=xim&rand=" + Math.random(),
                // isNew: true,
                logo: require("@/assets/images/logo/xim.png")
            },
            {
                name: "电影票",
                jingle: "航空积分兑换",
                // isNew: true,
                logo: require("@/assets/images/logo/movie.png"),
                link: "https://payment.jifen360.com/third_coupon/into?template=movie&rand=" + Math.random(),
            },
            {
                name: "小米影视",
                jingle: "航空积分兑换",
                // isNew: true,
                logo: require("@/assets/images/logo/xiaom.png"),
                link: "https://chqjf.91bak.com/p/xmys?rand=" + Math.random(),
            },
        ],
    }
}

export const mallLst = {
    active: "shopping",
    type: "banner",
    tabs: {
        shopping: "全积分商城",
        supplier: "优享返利"
    },
    data: {
        supplier: {
            image: require("@/assets/images/supplier.png"),
            link: "https://sichuan-airlines.jifen360.com/rebates/?$_=" + Math.random(),
            name:"全积分商城",
            jingle: ""
        },
        shopping: {
            image: require("@/assets/images/shopping.png"),
            link: "https://mall.jifen360.com/?$_=" + Math.random(),
            name:"优享返利",
            jingle: ""
        }
    }
}

export const adv = [
    {
        name: "积分换购新玩法",
        background: "#E6FFF8",
        image: "https://images.jifen360.com/sys/sys-banner-change.jpg",
        url: "https://payment.jifen360.com/",
        title: "积分换购"
    },
    {
        name: "消费送航空积分",
        background: "#FFF8EB",
        image: "https://images.jifen360.com/sys/sys-banner-shopping.jpg",
        url: "https://mall.jifen360.com",
        title: "全积分商城"
    },
    {
        name: "川航里程兑换瑞幸咖啡优惠券",
        image: "https://images.jifen360.com/sys/sys-banner-luckin.png",
        url: "https://sichuan-airlines.jifen360.com/coupon_life/?coupon=luckin",
        title: "瑞幸咖啡",
        background: "rgba(49,78,170,0.25)"
    },
    {
        name: "川航里程兑换书亦烧仙草优惠券",
        image: "https://images.jifen360.com/sys/sys-banner-shuyi.png",
        url: "https://sichuan-airlines.jifen360.com/coupon_life/?coupon=shuyi",
        title: "书亦烧仙草",
        background: "rgba(228,249,216,0.45)"
    }
]
