import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        scanStoreInfo: {},
        scanUserInfo: {},
        loungeStoreInfo: {},
        jianfuStoreInfo:{}
    },
    getters: {},
    mutations: {
        getScanStoreInfo(state, playLoad) {
            if (!playLoad) {
                const storeInfo = window.sessionStorage.getItem("scanStoreInfo")
                if (storeInfo) {
                    this.state.scanStoreInfo = JSON.parse(storeInfo)
                } else {
                    this.state.scanStoreInfo = {}
                }
            } else {
                window.sessionStorage.setItem("scanStoreInfo", JSON.stringify(playLoad))
                this.state.scanStoreInfo = playLoad
            }
        },
        getJianfuStoreInfo(state, playLoad) {
            if (!playLoad) {
                const storeInfo = window.sessionStorage.getItem("jianfuStoreInfo")
                if (storeInfo) {
                    this.state.jianfuStoreInfo = JSON.parse(storeInfo)
                } else {
                    this.state.jianfuStoreInfo = {}
                }
            } else {
                window.sessionStorage.setItem("jianfuStoreInfo", JSON.stringify(playLoad))
                this.state.jianfuStoreInfo = playLoad
            }
        },
        getScanUserInfo(state, playLoad) {
            if (!playLoad) {
                const user = window.sessionStorage.getItem("scanUserInfo")
                if (user) {
                    this.state.scanUserInfo = JSON.parse(user)
                } else {
                    this.state.scanUserInfo = {}
                }
            } else {
                window.sessionStorage.setItem("scanUserInfo", JSON.stringify(playLoad))
                this.state.scanUserInfo = playLoad
            }
        },
        removeScanUserInfo(state) {
            window.sessionStorage.removeItem("scanUserInfo")
            this.state.scanUserInfo = {}
        },
        updateScanUserBalance(state, playLoad) {
            this.state.scanUserInfo.balance = playLoad
        },
        getLoungeStoreInfo(state, playLoad) {
            if (!playLoad) {
                const storeInfo = window.sessionStorage.getItem("loungeStoreInfo")
                if (storeInfo) {
                    this.state.loungeStoreInfo = JSON.parse(storeInfo)
                } else {
                    this.state.loungeStoreInfo = {}
                }
            } else {
                window.sessionStorage.setItem("loungeStoreInfo", JSON.stringify(playLoad))
                this.state.loungeStoreInfo = playLoad
            }
        }
    },
    actions: {},
    modules: {}
})
