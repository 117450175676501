import Request, {$_ajax} from "@/utils/request";
import wx from "weixin-js-sdk"
import axios from "axios";
import {Base64} from 'js-base64'

export function timerD() {
    if (this.djs <= 0) {
        return clearTimeout(this.timer)
    }

    this.timer = setTimeout(() => {
        this.djs--
        if (this.djs < 1) {
            clearTimeout(this.timer)
        } else {
            this.timerD()
        }
    }, 1000)
}

export function _alert(message, title, btn, callback) {
    let opts = {
        title: title || "提示",
        customClass: "vea-message-info vea-message-alert",
        type: "info",
        confirmButtonText: btn || "确认",
        confirmButtonClass: "vea-message-alert-btn"
    }

    if (callback) {
        opts.callback = callback
    }

    return window.app.$alert(message, opts)
}

export function _message(msg, type = "warning", timer = 3000, callback) {
    let option = {
        message: msg,
        type,
        duration: timer,
        customClass: "vea-message-info"
    }

    if (callback) {
        option.onClose = callback
    }
    return window.app.$message(option)
}

export const desensitized = (value, start, len) => {
    if (!value && (start + len) >= value.length) {
        return "";
    }
    let first = value.substring(0, start)
    let last = value.substring(value.length - len, value.length)
    let _replace = value.substring(start, (start + len)).split("").map(i => {
        return "*"
    })
    return first + _replace.join("") + last
}

export const check_login = async (goTo, back) => {
    window.app.$store.commit('getScanUserInfo', null)
    const userInfo = window.app.$store.state.scanUserInfo
    if (Object.keys(userInfo).length < 1) {
        window.app.$store.commit('removeScanUserInfo')
        return _alert("请登录", "提示", "确认", () => {
            if (back) {
                back()
            } else {
                window.app.$router.push({name: goTo})
            }
        })
    }
    //校验登录信息是否有效
    await new Request({
        encode: "site",
        method: "user/check_login",
        body: {
            cust_id: userInfo.cust_id,
            token: userInfo.token
        }
    }).send(res => {
        if (res.message.code !== "000") {
            window.app.$store.commit('removeScanUserInfo')
            return _alert("请登录", "提示", "确认", () => {
                window.app.$router.push({name: goTo})
            })
        }
        res.data.phoneNumber = Base64.decode(res.data.phoneNumber)
        window.app.$store.commit('updateScanUserBalance', res.data.balance)
        window.app.$store.commit("getScanUserInfo", res.data)
    }, () => {
        return _alert("请登录", "提示", "确认", () => {
            window.app.$store.commit('removeScanUserInfo')
            if (back) {
                back()
            } else {
                window.app.$router.push({name: goTo})
            }
        })
    })
}

export const goto = (url) => {
    location.href = url
}

export const wx_share = (ites) => {
    const url = process.env.NODE_ENV === "development" ? "http://localhost:8080/share" : "https://cloud.jifen360.com/wx_js_sdk"
    axios.post(url, {url: location.href}).then(({data}) => {
        wx.config({
            debug: false,
            appId: data.appId,
            timestamp: data.timestamp,
            nonceStr: data.nonceStr,
            signature: data.signature,
            jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData"
            ],
        });
        wx.ready(() => {
            let shareData = {
                title: ites.title,
                desc: ites.desc,
                link: ites.url,
                imgUrl: ites.images,
                success: () => {
                },
                fail: (res) => {
                    console.log(res)
                }
            };
            wx.updateAppMessageShareData(shareData)
            wx.updateTimelineShareData(shareData)
        });
    })
}
export const fookScoreList = [
    {name: "四川航空金熊猫里程", mark: "scal"},
    {name: "厦门航空白鹭积分", mark: "xm_air"},
    {name: "中国移动积分", mark: "cmcc"}
]
export const isWeixin = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) === "micromessenger";
}

export const onWayFn = (query, call) => {
    //加密字符串
    $_ajax.get(`${process.env.VUE_APP_ONE_WAY}scal/check`, {params: query}).then(({data}) => {
        if (data.data) {
            data.data.phoneNumber = Base64.decode(data.data.phoneNumber)
            window.app.$store.commit("getScanUserInfo", data.data)
            if (call) call()
            return;
        }
        window.app.$store.commit("removeScanUserInfo");
        //更新链接
        window.location.replace(`${location.protocol}//${location.host}${location.pathname}`);
    }).catch(() => {
        window.app.$store.commit("removeScanUserInfo");
        window.location.replace(`${location.protocol}//${location.host}${location.pathname}`)
    })
}
