import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueWechatTitle from 'vue-wechat-title'
import router from './router'
import store from './store'
import * as filters from './utils/filters'

Vue.use(VueWechatTitle)
Vue.use(ElementUI)
Vue.config.productionTip = false
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]))

window.app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
