import axios from "axios";
import md5 from "js-md5";

export const url = process.env.VUE_APP_API_URL
const shareBase = `https://payment.jifen360.com${process.env.VUE_APP_PUBLIC_PATH}/`
export const share = {
    scan: `${shareBase}scan?mark=`,
    lounge: `${shareBase}lounge?mark=`
}

export const $_ajax = axios.create({
    headers: {
        'Content-Type': 'application/json,charset=UTF-8',
    },
    // withCredentials: true
})

export const playMiles = {
    url: "https://scal-rest.jifen360.com/index.php",
    token: "dzr8EKb3TPlH7uZ1Qtaaxjatij2ibsJf",
    scalKeys: "sdfer43523fdf",
    scalChannelType: "JIFEN360"
}


class Request {
    #encode
    #body
    #header
    #enctry

    constructor(data) {
        this.#encode = {
            site: {
                url: `${url}site`,
                token: "468542ba9984fb15b985defaac25b7fb"
            },
            pay: {
                url: `${url}pay`,
                token: "3OOoP88ZPQx5dB69Np2NmeNjae0U8Hag"
            },
            city: {
                url: "https://api.jifen360.com/api.php",
                token: "468542ba9984fb15b985defaac25b7fb"
            }
            // play:{

            // }
        }
        this.#enctry = this.#encode[data.encode]
        this.#body = data.body
        this.#header = {
            method: data.method,
            sign: this.getSign(),
            times: this.timer()
        }
    }

    timer() {
        let date = new Date()
        let dateZero = (value) => {
            return parseInt(value) < 10 ? "0" + value : value;
        }

        return `${date.getFullYear()}-${dateZero(date.getMonth() + 1)}-${dateZero(date.getDate())} ${dateZero(date.getHours())}:${dateZero(date.getMinutes())}:${dateZero(date.getSeconds())}`
    }

    getSign() {
        let key = Object.keys(this.#body).sort()
        let text = key.map(i => {
            if (this.#body[i]) {
                return `${i}${this.#body[i]}`
            } else {
                return ""
            }
        })
        text = text.filter(function (s) {
            return s && s.trim();
        })
        text = text.join("");
        return md5(`${text}${this.#enctry.token}`).toUpperCase()
    }

    param() {
        return {
            header: this.#header,
            body: this.#body
        }
    }

    send(success, fail) {
        $_ajax.post(this.#enctry.url, (this.param())).then(succ => {
            if (success) {
                success(succ.data)
            }
        }).catch(err => {
            if (fail) {
                fail(err)
            }
        })
    }
}

export default Request
