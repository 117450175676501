import { render, staticRenderFns } from "./panel.vue?vue&type=template&id=6e723b2f&scoped=true"
import script from "./panel.vue?vue&type=script&lang=js"
export * from "./panel.vue?vue&type=script&lang=js"
import style0 from "./panel.vue?vue&type=style&index=0&id=6e723b2f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e723b2f",
  null
  
)

export default component.exports