<template>
    <div class="pages-home">
        <carousel :active_banner="active_banner"/>
        <div class="content">
            <panel title="积分当钱花" :info='scorePay' jingle="以下场景消费用积分结账"/>
            <panel title="消费送航空积分" :info="mallLst" jingle="购物免费·轻松便捷"/>
        </div>
    </div>
</template>

<script>

import Carousel from "@/components/sichuanAirportCarousel/carousel";
import Panel from "@/components/sichuanAirportCarousel/panel";
import {mallLst, scorePay} from "@/utils/conta";
import {wx_share} from "@/utils/func";
export default {
    name: 'HomeView',
    components: {
        Panel,
        Carousel
    },
    computed:{
        scorePay(){
            return scorePay
        },
        mallLst(){
            return mallLst
        },
        active_banner(){
            return [require("@/assets/images/banner.png")]
        }
    },
    mounted() {
        wx_share({
            title: "积分新玩法",
            desc:"消费用积分结账、消费送航空积分！",
            url: "https://payment.jifen360.com",
            images: "https://pays.jifen360.com/airport/image/airport/logo.jpg"
        })
    }
}
</script>

<style lang="less">
.pages-home {
    background: rgba(163, 164, 166, 0.15);

    .content{
        box-sizing: border-box;
        padding: 15px;
    }
}
</style>
