<template>
    <div class="banner">
        <el-carousel :interval="5000" arrow="never" class="com-carousel" height="220px">
            <el-carousel-item v-for="official in active_banner">
                <el-image :src="official">
                    <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                    </div>
                </el-image>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    name: "carousel",
    props: ['active_banner']
}
</script>

<style lang="less" scoped>
.banner {
  box-sizing: border-box;
  padding: 15px 15px 0;
    margin-bottom: 10px;
  background: linear-gradient(180deg, rgba(255, 237, 248, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.com-carousel {
  //height: 220px;
  overflow: hidden;
  border-radius: 15px;
  //margin-bottom: 15px;

  /deep/ .el-image {
    height: 220px;
    width: 100%;
    //border-radius: 10px;
  }
}
</style>
