export const qrcodeUri = (code) => {
    return `https://open.jifen360.com/qrcode/${code}`
}

export const barcodeUri = (code) => {
    return `https://cloud.jifen360.com/barcode/${code}`
}

export const replaceStr = str=>{
    return str.replace(/(.{4})/g,"$1 ")
}
