<template>
  <div>
    <el-card shadow="never" style="border-radius: 10px; margin-bottom: 25px" :body-style="{padding:'15px 15px 5px'}">
      <div class="com-panel-title">
        <div class="com-panel-title-left">
          {{ title }}
        </div>
        <div class="com-panel-title-right">{{ jingle }}</div>
      </div>
      <div class="com-panel-tabs">
        <div class="com-panel-tabs-info" v-for="(child,inx) in info.tabs" :key="inx">
          <el-button round plain size="small" :class="inx === active?'com-panel-tabs-actives':''"
                     @click="active = inx">{{ child }}
          </el-button>
        </div>
      </div>
      <div class="com-panel-tabs-content">
        <div class="com-panel-tabs-logo" v-if="info.type === 'logo'">
          <div class="com-panel-tabs-logo-its" v-for="data in changeData" @click="toWebSite(data)">
            <div class="com-panel-tabs-logo-its-logo" :style="`background-position: ${data.posix}`">
              <el-image v-show="data.logo" style="width: 100%" :src="data.logo"/>
              <el-image class="is_new" :src="is_new" style="width: 30px" v-if="data.isNew"/>
            </div>
          </div>
        </div>
        <div class="com-panel-tabs-banner" v-if="info.type === 'banner'">
          <el-image :src="changeData.image" @click="toWebSite(changeData)"/>
        </div>
      </div>
    </el-card>
    <el-dialog :title="changeSelect.name" :visible.sync="showModal" width="30%"
               custom-class="vea-message-info vea-message-alert">
      <h3>请选择{{ changeSelect.name }}兑换渠道</h3>
      <!--            showConfirm-->
      <div class="show-confirm-list">
        <div class="show-confirm-list-data" v-for="confirm in changeSelect.showConfirm" @click="to(confirm.link)">
          <el-image :src="confirm.logo" style="height: 65px;width: 65px;"/>
          <el-button type="text" size="mini">{{ confirm.name }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {$_ajax} from "@/utils/request";

export default {
  name: "panel",
  props: {
    title: {
      type: String
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    jingle: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    changeData() {
      return this.info.data[this.active]
    },
    is_new() {
      return require("@/assets/images/new.png")
    }
  },
  methods: {
    toWebSite(data) {
      if (data.notNet) return this.$msgbox.alert("当前业务升级中，敬请期待！", "提示", {
        customClass: "vea-message-info vea-message-alert"
      })
      if (data.showConfirm) {
        this.changeSelect = data
        this.showModal = true
        return
      }
      //记录点击数据
      $_ajax.get(`${process.env.VUE_APP_ONE_WAY}adv_click/click`, {
        params: {
          plate: "积分换购", adv: `${data.name}·${data.jingle}`, url: data.link
        }
      }).then(() => {
        this.to(data.link)
      }).catch(() => this.to(data.link))
    },
    to(url) {
      window.location.href = url;
    }
  },
  data() {
    return {
      active: '',
      changeSelect: {},
      showModal: false
    }
  },
  mounted() {
    this.active = this.info.active
  }
}
</script>

<style lang="less" scoped>
.com-panel-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  //flex-wrap: wrap;

  .com-panel-title-left {
    font-weight: 600;
    font-size: 18px;
    position: relative;
    padding-left: 8px;

    &:before {
      content: "";
      border-left: 5px solid #FF57B9;
      border-radius: 30px;
      display: inline-block;
      height: 15px;
      position: absolute;
      top: 50%;
      margin-top: -7.5px;
      left: 0;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 45px;
      height: 45px;
      background: url("@/assets/images/title-tag.png") no-repeat;
      background-size: 100%;
      position: absolute;
      top: -10px;
      left: 85%;
    }
  }

  .com-panel-title-right {
    font-size: .7rem;
    color: #808080;
    letter-spacing: 3px;
  }
}

.com-panel-tabs {
  display: flex;
  align-items: center;

  .com-panel-tabs-info {
    box-sizing: border-box;
    padding-right: 8px;

    .el-button {
      padding-left: 9px;
      padding-right: 9px;
    }

    .com-panel-tabs-actives {
      background: linear-gradient(90deg, rgba(252, 162, 215, 1) 0%, rgba(226, 0, 132, 1) 100%);
      color: #fff;
      border-color: #fff;
      font-weight: 600;
    }
  }
}

.com-panel-tabs-content {
  .com-panel-tabs-logo {
    margin: auto;
    display: flex;
    overflow-x: auto;
    flex-wrap: wrap;

    .com-panel-tabs-logo-its {
      box-sizing: border-box;
      text-align: center;
      line-height: 1.75;
      font-size: .65rem;
      flex: 0 0 20%;
      padding: 0 5px;


      .com-panel-tabs-logo-its-logo {
        position: relative;
        margin: 10px auto;

        .is_new {
          position: absolute;
          top: 3px;
          right: -5px;
        }
      }

      .com-panel-tabs-logo-its-jingle {
        color: #c1c1c1;
        font-size: .55rem;
      }
    }
  }
}

.com-panel-tabs-banner {
  margin-top: 15px;
}

.show-confirm-list {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  margin-top: 15px;
  flex-wrap: wrap;

  .show-confirm-list-data {
    flex: 0 0 25%;
    text-align: center;
    box-sizing: border-box;
    padding: 15px;
  }
}
</style>
