import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: "积分新玩法"
        }
    },
    {
        path: '/user/login',
        name: 'user_login',
        component: () => import( '../views/user/Login.vue'),
        meta: {
            title: "四川航空-会员登录"
        }
    },
    {
        path: '/forget/index/:backTo',
        name: 'forget',
        component: () => import( '../views/Forget.vue'),
        meta: {
            title: "四川航空-修改密码"
        }
    },
    {
        path: '/scan',
        name: 'scan',
        component: () => import( '../views/scan/Index.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/scan/pay",
        name: "scan_pay",
        component: () => import('../views/scan/Pay.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/scan/code",
        name: "scan_code",
        component: () => import('../views/scan/Code.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/scan/successful/:order_sn",
        name: "scan_successful",
        component: () => import('../views/scan/Successful.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/scan/order_list",
        name: "scan_order_list",
        component: () => import('../views/scan/OrderList.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/lounge",
        name: "lounge",
        component: () => import('../views/lounge/Index.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/lounge/goods",
        name: "lounge_goods",
        component: () => import('../views/lounge/Goods.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/lounge/success/:goods_number/:miles/:goods_name",
        name: "lounge_success",
        component: () => import('../views/lounge/Success.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/lounge/user/order_list",
        name: "lounge_user",
        component: () => import('../views/lounge/UserCenter.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/offline",
        name: "offline",
        component: () => import('../views/offline/index.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/offline/pay",
        name: "offline_pay",
        component: () => import('../views/offline/Pay.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/offline/success/:order",
        name: "offline_success",
        component: () => import('../views/offline/Success.vue'),
        meta: {
            title: "四川航空里程线下兑换"
        }
    },
    {
        path: "/tibet_airport/goods/:id",
        name: "tibetAirportGoods",
        component: () => import('../views/tibet/Goods.vue'),
        meta: {
            title: "西藏航空会员权益"
        }
    },
    {
        path: "/sichuan_airport/play/miles",
        name: "sichuan_airport_play_miles",
        component: () => import('@/views/PlayMilesView.vue'),
        meta: {
            title: "玩转里程"
        }
    },
    {
        path: "/sichuan_airport/city/lst",
        name: "sichuan_airport_city_lst",
        component: () => import('@/views/CityLst.vue'),
        meta: {
            title: "玩转里程"
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
